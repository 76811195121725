/* You can add global styles to this file, and also import other style files */

.simple-notification-wrapper {
  z-index: 100000 !important;
}

.simple-notification-wrapper.top {
  top: 70px !important;
}

.date-box-selected {
  border-color: #E94F3D;
  border-width: 2px;
  box-shadow: 0px 0px 6px 1px #E94F3D;
}


/*!
 * Start Bootstrap - One Page Wonder v5.0.6 (https://startbootstrap.com/template-overviews/one-page-wonder)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-one-page-wonder/blob/master/LICENSE)
 */

body {
  font-family: "Montserrat", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 800 !important;
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.bg-black {
  background-color: #000 !important;
}

.rounded-pill {
  border-radius: 5rem;
}

.navbar-custom {
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  background-color: white;
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.navbar-custom .navbar-brand img {
  width: 100px;
}

.navbar-custom .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: #797979;
}

header.masthead {
  position: relative;
  overflow: hidden;
  padding-top: calc(7rem + 72px);
  padding-bottom: 7rem;
  background: -webkit-gradient(linear, left bottom, left top, from(#ff6a00), to(#ee0979));
  background: linear-gradient(0deg, #ff6a00 0%, #ee0979 100%);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
}

header.masthead.green {
  background: -webkit-gradient(linear, left bottom, left top, from(#11998e), to(#38ef7d));
  background: linear-gradient(0deg, #11998e 0%, #38ef7d 100%);
}

header.masthead .masthead-content {
  z-index: 1;
  position: relative;
}

header.masthead .masthead-content .masthead-heading {
  font-size: 3rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.1em;
}

header.masthead .masthead-content .masthead-subheading {
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.1em;
}

header.masthead .bg-circle {
  z-index: 0;
  position: absolute;
  border-radius: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(#ee0979), to(#ff6a00));
  background: linear-gradient(0deg, #ee0979 0%, #ff6a00 100%);
}

header.masthead.green .bg-circle {
  background: -webkit-gradient(linear, left bottom, left top, from(#00F260), to(#0575E6));
  background: linear-gradient(0deg, #00F260 0%, #0575E6 100%);
}

header.masthead .bg-circle-1 {
  height: 90rem;
  width: 90rem;
  bottom: -55rem;
  left: -55rem;
}

header.masthead .bg-circle-2 {
  height: 50rem;
  width: 50rem;
  top: -25rem;
  right: -25rem;
}

header.masthead .bg-circle-3 {
  height: 20rem;
  width: 20rem;
  bottom: -10rem;
  right: 5%;
}

header.masthead .bg-circle-4 {
  height: 30rem;
  width: 30rem;
  top: -5rem;
  right: 35%;
}

@media (min-width: 992px) {
  header.masthead {
    padding-top: calc(10rem + 55px);
    padding-bottom: 10rem;
  }

  header.masthead .masthead-content .masthead-heading {
    font-size: 5rem;
  }

  header.masthead .masthead-content .masthead-subheading {
    font-size: 4rem;
  }
}

.bg-primary {
  background-color: #375D9D !important;
}

.btn-primary {
  background-color: #375D9D;
  border-color: #375D9D;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: rgb(29, 86, 185) !important;
  border-color: rgb(29, 86, 185) !important;
}

.btn-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(55, 92, 157, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(55, 92, 157, 0.5);
}

.btn-secondary {
  background-color: #E94F3D;
  border-color: #E94F3D;
}

.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  background-color: #cc5500 !important;
  border-color: #cc5500 !important;
}

.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 106, 0, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 106, 0, 0.5);
}

header.masthead .bg-cover {
  padding: 0 !important;
  margin: 0 auto !important;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.line-breaker {
  white-space: pre-line;
}

@media (min-width: 34em) {
  .card-columns {
    column-count: 2;
  }
}

/*
@media (min-width: 34em) {
  .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
  }
}

@media (min-width: 48em) {
  .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
  }
}

@media (min-width: 62em) {
  .card-columns {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
  }
}

@media (min-width: 75em) {
  .card-columns {
      -webkit-column-count: 5;
      -moz-column-count: 5;
      column-count: 5;
  }
} */
